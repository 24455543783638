import { RouteRecordRaw } from 'vue-router'
import { Layout } from '@/routers/constant'

const router: Array<RouteRecordRaw> = [
  {
    path: '/account',
    component: Layout,
    redirect: '/account/index',
    meta: {
      title: '系统管理',
      keepAlive: true,
      requiresAuth: false,
      menu: true
    },
    children: [
      {
        path: 'user/index',
        name: 'UserIndex',
        component: () => import('@/views/app/user/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '员工管理',
          menu: true,
          admin: true
        }
      },
      {
        path: 'index',
        name: 'AccountIndex',
        component: () => import('@/views/app/account/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '诊所资料',
          menu: true
        }
      },
      {
        path: 'wallet/index',
        name: 'AccountWalletIndex',
        component: () => import('@/views/app/account/wallet/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '账户管理',
          menu: true,
          admin: true
        }
      },
      {
        path: 'wallet/withdrawal',
        name: 'AccountWalletWithdrawal',
        component: () => import('@/views/app/account/wallet/withdrawal.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '提现记录',
          activeMenu: '/account/wallet/index'
        }
      },
      {
        path: 'beans',
        name: 'AccountBeans',
        component: () => import('@/views/app/account/beans/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '金豆充值',
          menu: true,
          admin: true
        }
      },
      {
        path: 'order/recharge',
        name: 'AccountOrderRecharge',
        component: () => import('@/views/app/order/recharge/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '充值订单',
          activeMenu: '/account/beans'
        }
      },
      {
        path: 'packages',
        name: 'AccountPackages',
        component: () => import('@/views/app/account/packages/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '活动套餐',
          menu: true,
          admin: true
        }
      },
      {
        path: 'coupons/index',
        name: 'AccountCouponsIndex',
        component: () => import('@/views/app/account/coupons/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '产品卡券',
          menu: true,
          admin: true
        }
      },
      {
        path: 'coupons/my',
        name: 'AccountCouponsMy',
        component: () => import('@/views/app/account/coupons/my.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '我的卡券',
          activeMenu: '/account/coupons/index'
        }
      },
      {
        path: 'pricing',
        name: 'AccountPricing ',
        component: () => import('@/views/app/account/pricing/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '定价设置',
          menu: true,
          admin: true
        }
      },
      {
        path: 'products',
        name: 'AccountProducts',
        component: () => import('@/views/app/account/products/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '产品中心',
          menu: true,
          admin: true,
          sysOvertimeType: true
        }
      },
      {
        path: 'products/record',
        name: 'AccountProductsRecord',
        component: () => import('@/views/app/account/products/record/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '缴费记录',
          menu: true,
          admin: true,
          sysOvertimeType: true
        }
      },
      {
        path: 'updatePass',
        name: 'AccountUpdatePass',
        component: () => import('@/views/app/account/updatePass/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '修改密码',
          menu: true
        }
      },
      {
        path: 'userSchedule',
        name: 'AccountUserSchedule',
        component: () => import('@/views/app/account/schedule/schedule.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '排班管理',
          menu: true
        }
      },
      {
        path: 'coupons/detail',
        name: 'AccountCouponDetail',
        component: () => import('@/views/app/account/coupons/detail.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '卡券活动',
          activeMenu: '/account/coupons/index'
        }
      },
      {
        path: 'notice/index',
        name: 'AccountNotice',
        component: () => import('@/views/app/account/notice/index.vue'),
        meta: {
          keepAlive: true,
          requiresAuth: false,
          title: '系统消息',
          menu: true
        }
      }
    ]
  }
]

export default router
